module.exports = {
  kiamoLiveChat: function (userId, flux, cp, motif) {

    // TODO Explain
    fluxToJson = (flux, max_size) => {
      jsonFlux = {"bot":[]}
      temp_flux = flux
      if (temp_flux.length > max_size) {
        temp_flux = temp_flux.slice(-max_size)
      }
      temp_flux.forEach(msg => {
        if (msg.type==="text")
          jsonFlux["bot"].push({"from": msg.data.from, "msg":msg.data.content.replace(/[{}'’\\:]/g, " ")})
      });
      return jsonFlux
    }

    max_size = flux.length
    Chatbot_histo = btoa(encodeURIComponent(JSON.stringify(fluxToJson(flux, max_size))), 'base64');
    max_size = max_size - 5
    while(Chatbot_histo.length > 9000) {
      Chatbot_histo = btoa(encodeURIComponent(JSON.stringify(fluxToJson(flux, max_size))), 'base64');
      max_size = max_size - 5
      if (max_size < 0) { max_size = 0 }
    }

    Chatbot_zone = btoa(encodeURIComponent(cp), 'base64');
    Chatbot_motif = btoa(encodeURIComponent(motif), 'base64');
    Chatbot_sessionID = btoa(encodeURIComponent(userId), 'base64');

    // console.log(Chatbot_histo)
    // console.log(fluxToJson(flux))
    // console.log(window.atob(Chatbot_zone))
    // console.log(window.atob(Chatbot_motif))

    try {
      // kcChat6e7ac6387a56610f7c23f58a87811548.kcStartConversation(event); //pprod
      kcChat6330cc2787268d699a20940cd99d2787.kcStartConversation(event); //prod
    } catch (error) {
      console.log("error livechat kiamo : ", error)
      
      return "error"
    }
  }
}
