<template>
  <div v-bind:class="mode(content.from)">
    <div v-bind:class="mode('message')">{{content.userName}}</div>
  </div>
</template>

<script>
  import {accessibilityService} from '../../services/AccessibilityService';

  export default {
  name: "UserMessage",
  props: {
    content: Object
  },
  methods: {
    mode: function (value) {
      return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
    },
  }
};
</script>

<style lang="scss" scoped>
.message {
  font-weight: bold;
  text-align: left;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  background-color: $basic-color-fond;
  color: $user-fonts;
  font-size: $font-size_14PX;
  @media only screen and (max-device-width: $max_device_width_phone) {
    font-size: $font-size-min-IOS;
  }
  margin-top: 10px;
}

.sent {
  margin-left: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.received {
  margin-right: 20%;
}
</style>
