import Vue from 'vue'
import App from './App.vue'
import VueSimpleAlert from "vue-simple-alert"

Vue.use(VueSimpleAlert)

Vue.config.productionTip = false

new Vue({
  el: '#app-chatbot',
  render(h) { return h(App) }
})