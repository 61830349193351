<template>
    <div v-bind:class="mode(content.from)">
        <div v-if="showOverlay" v-bind:class="mode('fullscreen-img')">
            <div v-if="isMobile" v-on:click="showImage()" v-bind:class="mode('overlay-mobile')"></div>
            <div v-if="!isMobile" v-on:click="showImage()" v-bind:class="mode('overlay')"></div>
            <div v-bind:class="mode(isMobile? 'zoomed-img-mobile':'zoomed-img' )">
                <div v-bind:class="mode('cross-box')">
                    <img
                            v-on:click="showImage()"
                            v-on:mouseover="buttonHover('cross')"
                            v-on:mouseleave="buttonLeave('cross')"
                            v-bind:class="[mode('cross'), { crossOver: cross }]"
                            src="../../assets/croix.svg"
                    />
                </div>
                <div v-bind:class="mode('image-box')">
                    <!--TODO remove this link and content.value?? -->
                    <img v-bind:class="mode('zoomed-content')"
                         :src="imageValue"/>
                </div>
                    <div v-bind:class="mode('footer')">
                </div>
            </div>
        </div>


        <div v-if="content.from == 'received'" v-bind:class="mode('image')">
            <!--TODO remove this link and content.value?? -->
            <img v-bind:class="mode('mini')"
                 :src="imageValue"/>
            <div v-bind:class="mode('img-buttons')">
                <div v-bind:class="mode('action-container')">
                    <img
                            v-if="!this.isMobile"
                            v-on:click="showImage()"
                            src="../../assets/increase.png"
                            v-on:mouseover="buttonHover('hoverInc')"
                            v-on:mouseleave="buttonLeave('hoverInc')"
                            v-bind:class="[mode('mini'), { buttonOver: hoverInc }]"
                    />
                    <img
                            v-if="this.isMobile"
                            v-on:click="showImage()"
                            src="../../assets/increase-small.png"
                            v-on:mouseover="buttonHover('hoverInc')"
                            v-on:mouseleave="buttonLeave('hoverInc')"
                            v-bind:class="[mode('mini'), { buttonOver: hoverInc }]"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {accessibilityService} from '../../services/AccessibilityService';
    import apiService from "../../services/ApiService";

    export default {

        name: "ImageMessage",
        props: {
            content: Object,
        },
        methods: {
            mode: function (value) {
                return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
            },
            showImage: function () {
                this.showOverlay = !this.showOverlay;
            },
            buttonHover: function (button) {
                this[button] = true;
            },
            buttonLeave: function (button) {
                this[button] = false;
            },
            handleResize () {
                this.window.width = window.innerWidth;
                if (this.window.width < 1000) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
                this.window.height = window.innerHeight;
            },
            loadImage: async function () {
				const blob = await apiService.loadFile(this.content['url']);
                this.source = window.URL.createObjectURL( blob );
            }
        },
        computed: {
            imageValue () {
                return this.content['url'];
            }
        },
        data () {
            return {
                footerDl: false,
                showOverlay: false,
                hoverDl: false,
                hoverInc: false,
                cross: false,
                isMobile: false,
                window: {
                    width: 0,
                    height: 0
                },
                source: '',
            };
        },
        created () {
            window.addEventListener("resize", this.handleResize);
            this.handleResize();
        },
        destroyed () {
            window.removeEventListener("resize", this.handleResize);
        }
    };
</script>

<style lang="scss" scoped>

    .mini,
    a {
        display: block;
        width: auto;
        max-width: 80%;
        height: 100%;
        border-radius: 6px;
    }

    .crossOver {
        cursor: pointer;
    }

    .overlay,
    .overlay-mobile,
    .fullscreen-img {
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 3 !important;
        justify-content: center;
        align-items: center;
    }

    .image-box {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .zoomed-img {
        width: fit-content;
        max-width: 90%;
        height: auto;
        justify-content: flex-start;
        z-index: 5;
        display: flex;
        flex-direction: column;
        background-color: $basic-color;
    }

    .zoomed-img-mobile {
        width: fit-content;
        max-width: 90%;
        height: auto;
        justify-content: flex-start;
        z-index: 5;
        display: flex;
        flex-direction: column;
    }

    .footer {
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        height: 2.3rem;
        justify-content: space-between;
        display: flex;
    }

    .cross-box {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.5rem;
    }

    .cross {
        padding-top: 0.5rem;
        padding-right: 0.5rem;
    }

    .footerDl {
        height: 2rem;
    }

    .overlay {
        background-color: $overlay;
        opacity: 0.5;
    }

    .overlay-mobile {
        background-color: $basic-color;
    }

    .imgName {
        color: $image-name;
    }

    .image {
        padding: 10px 0px 10px 0px;
        display: flex;
        background-color: $basic-color !important;
        width: 100%;
        max-height: 10rem;
        height: 8rem;
    }

    .img-buttons {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: center;
        padding: 0rem 1rem 0rem 1rem;
    }

    .action-container {
        justify-content: flex-start;
        display: flex;
        margin-top: 1rem;
        height: 2rem;
    }

    .action-container-sent {
        justify-content: flex-end;
        display: flex;
        margin-top: 1rem;
        height: 2rem;
    }

    .zoomed-content {
        max-width: 100%;
        height: auto;
        max-height: 30rem;
    }

    .buttonOver {
        cursor: pointer;
        height: 2.3rem;
    }

    .sent {
        margin-left: 20%;
        display: flex;

        .image {
            justify-content: flex-end;
        }
    }

    .received {
        align-items: flex-start;
        margin-right: 20%;

        .image {
            justify-content: flex-start;
        }
    }
</style>
