<template>
    <div>
        <div v-bind:class="mode('chatbot-header')">
            <div v-bind:class="mode('chatbot-titles')">
                <div v-bind:class="mode('chatbot-title')">Conversation avec <b>le chatbot</b></div>
                <button aria-label="minimiser la fenêtre chatbot" @click="closeChatbot()" v-bind:class="mode('gg-chevron-down-o')">
                    <span class="chatbot-tooltip">Cliquez ici pour réduire la fenêtre du chatbot</span>
                </button>
            </div>
        </div>
    </div>
</template>


<script>
export default {

}
</script>

<style>

</style>

<script>
    import {accessibilityService} from '../services/AccessibilityService';
	import last_mess from "./ChatBotBody.vue";

    export default {
        name: "ChatBotHeader",
        data () {
            return {
                isMobile: false,
                window: {
                    width: 0,
                    height: 0
                }, 
				vue_last_mess: "test"
            };
        },
        created () {
            window.addEventListener("resize", this.handleResize);
            this.handleResize();
        },
        destroyed () {
            window.removeEventListener("resize", this.handleResize);
        },
        methods: {
            mode: function (value) {
                return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
            },
            closeChatbot: function () {
                this.$emit("closeChat", true);
            },
            handleResize () {
                this.window.width = window.innerWidth;
                if (this.window.width < 550) {
                        this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
                this.window.height = window.innerHeight;
            }
        }
    };
</script>

<style lang="scss" scoped>

    .chatbot-mobile-header{
        display: flex;
        justify-content:flex-end;
        background-color:$basic-color;
        height: 100%;
    }

    .chatbot-chatbot-main-hover {
        .on-hover-parent {
            transition: opacity 0.25s linear;
            opacity: 0;
            position: absolute;
            margin-top: 0.5rem;
            width: 0;
            text-align: right;
            padding: unset;
            overflow: visible;
            margin-left: 35px;
        }

        .on-hover-child {
            display: none;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            border: solid 2px #a39382;
            background-color: #ffffff;
            font-family: "Avenir", Helvetica, Arial, sans-serif;
            font-size: $font-size_12PX;
            @media only screen and (max-device-width: $max_device_width_phone) {
                font-size: $font-size-min-IOS;
            }
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.23px;
            width: max-content;
            right: 0;
            position: absolute;

        }

        &:hover {
            .on-hover-parent {
                opacity: 1;
            }

            .on-hover-child {
                display: block;
            }
        }
    }

    .chat-bot-header--button-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
    }

    .chatbot-supp {
        background-color: $basic-color;
        width: 100%;
        height: 1.5rem;
        border-right: 0.1px solid lightgray ;
        border-left: 0.1px solid lightgray ;
    }

    .chatbot-header {
        height: 56px;
        flex-basis: 10%;
        width: 100%;
        background-color: white;
        border-bottom: 1px #D0D3F8 solid;
        border-top-left-radius: 1.3rem;
        border-top-right-radius: 1.3rem;
    }

    .chatbot-reduce {
        border:none;        
        transition-duration: 0.4s;
        height:100%;
        cursor: pointer;

        .chat-bot-header--button-image {
            color:  #0d61b7;
            font-size: 2.4rem;
            font-weight:900;
        }
        &:hover {
            background-color: #f2f2f2;
        }
        &:focus {
            outline: -webkit-focus-ring-color auto 2px;
            outline: 2px solid black;
        }
    }

    .chatbot-titles {
        padding: 0% 4% 0% 4%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .chatbot-title {
            text-align: left;
            color: $title-color;
            font-family: $basic-font;
            letter-spacing: 0.6px;
            text-shadow: 0.4px 0.4px 0.4px #add8e6;;
            font-size: $font-size_16PX;
            @media only screen and (max-device-width: $max_device_width_phone) {
                font-size: $font-size-min-IOS;
            }
        }
    }

    .chatbot-button {
        background: unset;
        outline: unset;
    }

    .gg-chevron-down-o {
        box-sizing: border-box;
        position: relative;
        display: block;
        transform: scale(var(--ggs,1));
        width: 26px;
        height: 26px;
        border-radius: 100px;
        background-color: #1423DC;
        border-style: none;
    }
    .gg-chevron-down-o::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 6px;
        height: 6px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg);
        color: #ffffff;
        left: 10px;
        top: 8px
    }
    .gg-chevron-down-o:hover {
        background-color: #232873;
        cursor: pointer;
    }
    /*    TOOLTIP minimize window    */
    /*-------------------------------*/
    .chatbot-tooltip {
        position: absolute;
        top: 0;
        font-size: $font-size_14PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
        background: $basic-color-over;
        color: $basic-color;
        padding: 5px 8px;
        border-radius: 5px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        width:80px;
        margin: -50px 0px 0px -40px; // bidouillage pour positionner la bulle, TODO le rendre plus propre
    }
    .chatbot-tooltip::before {
      position: absolute;
      content: "";
      height: 8px;
      width: 8px;
      background: #ffffff;
      bottom: -3px;
      left: 50%;
      transform: translate(-50%) rotate(45deg);
      transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    .gg-chevron-down-o:hover .chatbot-tooltip {
      top: -45px;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }

</style>