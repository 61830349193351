
<template>
<div class='message-loader-2'>
    <div  class='loader-bar-2'></div>
    <div  class='loader-bar-2'></div>
    <div  class='loader-bar-2'></div>
</div>
</template>

<script>
    import {accessibilityService} from '../../services/AccessibilityService';

    export default { 
        name: "DotsMessage",
        methods: {
            image: function () {
                const path = accessibilityService.getTheme() === 'default' ? '/dots.gif' : '/animation-points-jaune.gif';
                return require("../../assets" + path);
            },
            mode: function (value) {
                return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
            },
        }
    };
</script>

<style lang="scss" scoped>
    img {
        height: 1.3rem;
        width: auto;
    }

    .received {
        display: flex;
        align-items: flex-start;
        margin-right: 20%;
    }


    $light-bg: #C9CBCB;
    $mobi-primary: $basic-color;
    $mobi-primary-dark: $basic-color;

    *{ box-sizing: border-box; }
    body{    padding: 20px; }

    .message-loader-2{
        align-items: center;
        //border: 1px solid $light-bg;
        border-radius: 15px;
        display: flex;
        height: 20px;
        justify-content: space-between;
        padding: 0 5px;
        position: relative;
        width: 40px;
        
        .loader-bar-2{
            background: $light-bg;
            border-radius: 5px;
            height: 8px;
            position: relative;
            width: 8px;
            &:first-of-type{ animation: chat-typing-indicator 1.5s infinite; }
            &:nth-of-type(2){ animation: chat-typing-indicator 1.5s infinite 0.2s; }
            &:nth-of-type(3){ animation: chat-typing-indicator 1.5s infinite 0.4s; }
        }
    }

    @keyframes chat-typing-bar {
        0% { width: 8px; }
        25% { width: calc(100% - 10px); }
        50% { width: 8px; }
        75% { width: calc(100% - 10px); }
        100% { width: 8px; }
        
        25% { left: 5px; }
        50% { left: calc(100% - 13px); }
        75% { left: 5px; }
        
        0% { background: $mobi-primary; }
        50% { background: $mobi-primary-dark; }
        100% { background: $mobi-primary; }
    }

    @keyframes chat-typing-indicator {
        0% { top: 0; }
        6.25% { top: -1px; }
        12.5% { top: 0; }
        18.75% { top: 1px; }
        25% { top: 0; }
        
        12.5% { background: $mobi-primary; }
    }

</style>
