<template>
    <div v-bind:class="mode(content.from)">
        <div v-bind:class="mode('chatbot-buttons')">
            <div v-bind:class="mode('chatbot-buttonsContainer')">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    v-for="(theme, index) in content.themes"
                    v-bind:key="theme.title"
                    v-bind:href="theme.url"
                    v-on:mouseover="buttonHoverOn(index)"
                    v-on:mouseleave="buttonHoverOff(index)"
                    v-on:click="emmitMessage(theme.url, theme.title, theme.payload)"
                    :class="[mode('chatbot-button'), { chatbotbuttonOver: indexes.includes(index) }]"
                    >{{ theme.title }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {accessibilityService} from '../../services/AccessibilityService';

    export default {
        name: "ButtonsMessage",
        data() {
            return {
            indexes: [],
            buttonOverValue: false,
            activeIndex: undefined,
            };
        },
        props: {
            content: Object
        },
        methods: {
            mode: function (value) {
                return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
            },
            buttonHoverOn: function (value) {
                this.indexes.push(value);
            },
            buttonHoverOff: function (value) {
                const index = this.indexes.indexOf(value);
                this.indexes.splice(index, 1);
            },
            emmitMessage: function (buttonURL, buttonTitle, buttonPayload) {
                // si le bouton a une URL on ne renvoie pas de message
                if(buttonURL==null) {
                    this.$emit("buttonData", buttonTitle);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .chatbot-header {
        margin-bottom: 4px !important;
        color: $msg-received-text;
        font-size: $font-size_14PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
    }

    .chatbot-message {
        border-radius: 4px;
        margin-top: 3px;
        margin-bottom: 3px;
        padding: 10px;
        width: fit-content;
        display: flex;
        font-size: $font-size_13PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
    }

    .chatbot-buttons {
        flex-direction: column;
        display: flex;
        flex: 1;
        margin-bottom: 3px;
    }

    .chatbot-buttonsContainer {
        display:flex;
        flex-wrap: wrap;
    }

    .chatbot-button {
        font-weight: normal;
        font-size: $font-size_13PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
        border-color: $msg-button-border;
        border-style: solid;
        border-width: 1px;
        padding: 10px 15px;
        margin-right: 4px;
        margin-bottom:7px;
        display: flex;
        width: fit-content;
        color: $basic-color;
        background-color: white;
        border-radius: 20px;
        text-align:left;
        border: 2px solid $basic-color;
    }

    .chatbotbuttonOver {
        cursor: pointer;
        background-color: $basic-color-over;
    }

    /* Les règles pour .sent .received et .message sont à mettre dans User.vue */
    /* Si vous les mettez ici ils vont se faire écraser par les règles css du fichier User.vue */
    /* C'est pas très clair mais les règles .sent .received et .message sont à destination du titre des discussions ("Vous" et "Chatbot Enedis") */

</style>
