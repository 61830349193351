<template>
	<div id="mainContainer" :class="[{'chatbot-frame': this.openChat}]">
    
        <ChatBotHeader
            v-if="this.openChat"
            @closeChat="closeChatMeth"
            @whoAmI="whoAmIMeth"
            @RechercheDocumentaire="RechercheDocumentaireMeth"
        />
        <ChatBotBody
            v-show="this.openChat" 
            :msg="message" 
            ref="bodyComponent" 
            @close-chatbot-livechat="closeChatMeth"
            @askSatisfaction="askSatisfactionMeth"/>
        <ChatBotSatisfaction
            v-show="this.openChat"
            ref="satisfactionComponent"
            @sendSatisfactionScore="sendSatisfactionScoreMeth"
            @closeSatisfactionPopup="closeSatisfactionPopupMeth">
        </ChatBotSatisfaction>
        <ChatBotFooter ref="footerComponent" v-show="this.openChat && !this.hiddenChat" @inputData="updateMessage"/>
		<ChatbotBubble v-show="!this.openChat && !this.hiddenChat" @openChatEvent="openChatMeth" role="button" tabIndex="0" title="Démarrer le chatbot enedis"/>
    </div>
	
</template>

<script>
    import ChatBotHeader from "./ChatBotHeader.vue";
    import ChatBotBody from "./ChatBotBody.vue";
    import ChatBotSatisfaction from "./ChatBotSatisfaction.vue";
    import ChatBotFooter from "./ChatBotFooter.vue";
    import ChatbotBubble from "./ChatBotBubble.vue";
    import {accessibilityService} from '../services/AccessibilityService';
    import messageFactory from "../services/MessageFactory";

    export default {
        name: "ChatBot",
        components: {
            ChatBotHeader,
            ChatBotBody,
            ChatBotSatisfaction,
            ChatBotFooter,
            ChatbotBubble
        },
        data: function () {
            return {
                message: "", 
                whoAmIVar: false, 
                openChat: false,
                hiddenChat: false,
                firstOpen: true,
                satisfactionOpened: false,
                satisfactionSent: false
            };
        },
        created: function () {
            window.addEventListener('click', (event) => { //utilisé pour detecter un click utilisateur quelque soit l'endroit sur la page
                this.userclick(event);
            });
        },
        mounted: function () {

            var ua = navigator.userAgent;
            var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
            if (is_ie) {
                this.$alert(
                    "Internet Explorer n'est pas supporté par le Chatbot, nous vous conseillons d'utiliser plutôt Firefox, Edge ou Chrome",
                    "Avertissement",
                    "warning"
                )
            }
            
            // Si flux en cookie, chatbot ouvert
            if (sessionStorage.getItem('flux') !== null && sessionStorage.getItem('wasOpen') === 'true') {
                this.openChatMeth()
            }
        },
        methods: {
            mode: function (value) {
                return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
            },
            updateMessage (message) {
                if (message == this.message) {
                    message += " "
                }
                this.message = message;
            },
            whoAmIMeth () {
                this.$refs.bodyComponent.sendWhoAmI();
            },
            openChatMeth () {
                this.openChat = true;
                sessionStorage.setItem('wasOpen', true)
                if (this.firstOpen) {
                    this.$refs.bodyComponent.sendFirstMessage()
                    this.firstOpen = false
                }
                this.setOpenedChatAttribute()
            },
            closeChatMeth () {
                this.openChat = false;
                sessionStorage.setItem('wasOpen', false)
                this.setOpenedChatAttribute()
            },
            hideChatMeth () {
                this.openChat = false;
                sessionStorage.setItem('wasOpen', false);
                this.hiddenChat = true;
                this.setOpenedChatAttribute()
            },
            setOpenedChatAttribute() {
                let app = document.getElementById("app-chatbot");
                let att = "isopened"
                if (this.openChat == true)
                    app.setAttribute(att,"")
                else
                    app.removeAttribute(att);
            },
            RechercheDocumentaireMeth () {
                this.$refs.bodyComponent.rechercheDocu();
            },
            askSatisfactionMeth (buttons) {
                this.$refs.footerComponent.setDisable(true)
                this.$refs.satisfactionComponent.openPopup(buttons)
            },
            async closeSatisfactionPopupMeth () {
                this.$refs.footerComponent.setDisable(false)

                if(!this.satisfactionSent) {
                    this.$refs.bodyComponent.setSatisfactionCount(0);
                    await this.$refs.bodyComponent.answer("greetings", true, "choice", true);
                    this.$refs.bodyComponent.inactivityTime()
                }
            },
            async sendSatisfactionScoreMeth (score, reviewmessage) {
                this.satisfactionSent = true
                await this.$refs.bodyComponent.answer(score.toString(), true, "choice", true);
                let leaveSuggestion = reviewmessage!=""?"Yes":"No"
                await this.$refs.bodyComponent.answer(leaveSuggestion, true, "choice", true);
                if(leaveSuggestion == "Yes") {
                    await this.$refs.bodyComponent.answer(reviewmessage.toString(), true, "", true);
                }       
            },
            userclick (e) {
                // minimize chatbot ?
                if (this.openChat == true && e.target != undefined)
                {
                    let t = e.target
                    let main = document.getElementById("mainContainer")
                    
                    if (main.contains(t) || t.className=="chatbot-close" || t.className.indexOf("chatbot-satisfaction-button") !== -1 
                    || t.id.indexOf("chatbot-close-satisfaction-button") !== -1) 
                    {
                        //console.log("inside")
                    } 
                    else 
                    {
                        //console.log("outside",e.target)
                        this.closeChatMeth();
                    }
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .chatbot-frame {
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        box-shadow: 0 0 7px #ccc;
        border-radius: 1.3rem;
        width: 440px;
        height: 510px;
        background-color: $basic-color-fond;
    }

    @media screen and (max-width: 550px) {
        .chatbot-frame {
            justify-content: space-between;
            flex-direction: column;
            width: 100%;
            height:87vh;
            margin-bottom:0vh;
            margin-right:0vh;
            flex-direction: column;
            display: flex;
            box-shadow: 0 0 15px #ccc;
        }
    }

    .chatbot-frame_accessibility {
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        box-shadow: 0 0 7px #ccc;
        border-radius: 1.3rem;
    }
    
</style>
