<template>
  <div ref="messageZone" v-bind:class="mode('chatbot-body')" v-bind:id="mode('chatbot-body-id')" v-on:scroll="scrollshadow()">
    <div v-bind:class="mode('chatbot-messages-zone')">
      <div v-bind:class="mode('chatbot-fix')"></div>
      <div
        v-for="(message, index) in flux"
        :key="index"
        :class="mode(`chatbot-${message.data.from}`)"
        class="message-box"
      >
        <UserMessage v-if="message.type == 'user'" :content="message.data" />
        <TextMessage v-if="message.type == 'text'" :content="message.data" />
        <ButtonsMessage
          v-if="message.type == 'buttons'"
          :content="message.data"
          @buttonData="showTopic"
        />
        <ImageMessage v-if="message.type == 'image'" :content="message.data" />
      </div>
      <DotsMessage v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import TextMessage from "./messages/Text.vue";
import ButtonsMessage from "./messages/Buttons.vue";
import ImageMessage from "./messages/Image.vue";
import UserMessage from "./messages/User.vue";
import DotsMessage from "./messages/Dots.vue";

import messageFactory from "../services/MessageFactory";
import apiService from "../services/ApiService";
import { accessibilityService } from "../services/AccessibilityService";
import liveChat from "../services/LiveChat";

var satisfaction_count = 0;

export default {
  name: "ChatBotBody",
  components: {
    ImageMessage,
    TextMessage,
    ButtonsMessage,
    DotsMessage,
    UserMessage,
  },

  props: {
    msg: {
      type: String,
    },
  },

  data() {
    var uniqueUserID = sessionStorage.getItem("userId");
    if (!uniqueUserID){
      uniqueUserID = Date.now()+"-"+Math.random();
      sessionStorage.setItem("userId", uniqueUserID);
      sessionStorage.setItem("firstConnexion", "true");
    }

    return {
      flux: [],
      isLoading: false,
      userId: uniqueUserID,
      messageKiamo:
        "Je vous transfère vers un conseiller. Vous allez passer sur une nouvelle fenêtre de discussion mais ne vous inquiétez pas, le conseiller aura connaissance de notre échange !",
      last_message: "",
      nb_echanges: 0, //sert à valider au moins un échange valide avant de déclencher la question de satisfaction
      scrollbaractivated: false,
      currentIntent:"",
    };
  },


  mounted: function () {
    const msgZone = this.$refs.messageZone;
    if (sessionStorage.getItem("flux") !== null) {
      this.flux = JSON.parse(sessionStorage.getItem("flux"));
      const msgZone = this.$refs.messageZone;
      msgZone.scrollTop = msgZone.scrollHeight;
    }
    msgZone.scrollTop = msgZone.scrollHeight;
  },
  updated: function () {
    const msgZone = this.$refs.messageZone;
    msgZone.scrollTop = msgZone.scrollHeight;
  },
  methods: {
    mode: function (value) {
      return accessibilityService.getTheme() === "default"
        ? value
        : value + "_accessibility";
    },
    scrollshadow() {
      if (document.querySelector("#chatbot-footer-id") != null) { //si la satisfaction est active il n'y a pas de footer dans le html
        let textbody = document.querySelector("#chatbot-body-id");
        let footer = document.querySelector("#chatbot-footer-id")
      
        if(!this.scrollbaractivated) {
          this.scrollbaractivated = true;
          footer.classList.add("footerboxshadow");
        }
        else {
          if (textbody.offsetHeight + textbody.scrollTop >= textbody.scrollHeight) { //en bas de la scrollbox
            footer.classList.remove("footerboxshadow");
          }
          else {
            footer.classList.add("footerboxshadow");
          }
        }
      }
    },
    livechatCloseBot: function () {
      this.$emit("close-chatbot-livechat");
    },
    sendWhoAmI: async function () {
      this.answer("Qui etes vous?", false, "sentence");
    },
    showTopic: function (topic) {
      if (this.$root.$refs.ChatBotSatisfaction.opened === true) {
        this.$root.$refs.ChatBotSatisfaction.closePopup()
      }
      this.answer(topic, false, "choice");
    },
    sendFirstMessage: function () {
      if(sessionStorage.getItem("firstConnexion") == "true") {
        sessionStorage.setItem("firstConnexion", "false");
        // apiService.sendWebsiteLocationPing(this.userId);
        this.answer("greetings", true, "sentence");
        this.last_message = "";
        sessionStorage.setItem("userId", this.userId);
        this.inactivityTime();
      }
      else { // on evite de renvoyer le greetings du front si le user refresh la page par exemple
      }
      
    },
    wait: function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    browseTypes: function (response) {
      let types = Object.keys(response);
      types.forEach((type) => {
        if (type === "card") {
          this.browseTypes(response[type]);
        } else {
          const message = messageFactory.createMessage(
            "received",
            type,
            response[type]
          );
          if (message) {
            let cp = ""
            let motif = ""
            // Tock ne supporte pas les champs libres, donc pour les classer on détecte un tag mis manuellement dans la
            // story complexe puis on ajoute un prefixe pour aider le nlp à classifier ce type de champ
            if (message.type == "text" && message.data.content.includes("[[[CHAMPLIBRE]]]")) {
              message.data.content = message.data.content.split("[[[")[0]
              this.prefix = "_CeciEstUnChampLibre_"
            }
            else if (message.type == "text" && message.data.content.includes("[[[")) {
              let param = message.data.content.split("[[[")[1].replace("[[[", "").replace("]]]", "")
              cp = param.split(',')[0].replace("cp=", "")
              motif = param.split(',')[1].replace("motif=", "")
              message.data.content = message.data.content.split("[[[")[0]
            }

            this.flux.push(message);
            this.last_message = message;
            
            // pour les malvoyants on rajoute le texte dans un span interactif pour leur pinger la réponse
            document.querySelector("#acessibilty-bot-answer").innerHTML = message.data.content;
            
            if (type === "text") {
              if (message.data.content == this.messageKiamo) {
                let resp = liveChat.kiamoLiveChat(this.userId, this.flux, cp, motif);
                if (resp == "error") {
                  this.answer("erreur_bascule_livechat", true, "sentence");
                }
                else {
                  this.livechatCloseBot();
                }
              }
              else if (message.data.content.includes("Veuillez nous excuser, aucun agent n'est disponible.")) {
                // console.log("Message caché !! On déclenche la story agent_indispo_ho");
                this.answer("pas d'agent disponible", true, "sentence");
              }
              else if (message.data.content.includes("Je suis désolé, je ne peux pas vous mettre en relation avec un conseiller en ce moment.")) {
                // console.log("Message caché !! On déclenche la story agent_indispo_hho");
                this.answer("agent hors heures ouvrées", true, "sentence");
              }
            }
          }
        }
      });
    },
    answer: async function (msg, hideMessage, eventType, hideAnswer) {
      function sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      }

      if (!(hideMessage === true)) {
        this.flux.push(messageFactory.createMessage("sent", "user", "Vous"));
        this.flux.push(messageFactory.createMessage("sent", "text", msg));
      }

      this.isLoading = true;

      if (eventType != "choice") {
        msg = msg.replace(/[.,\/#!$%\^&\*{}=\-_`~]/g, " ").toLowerCase();
      }
      if (this.prefix) {
        msg = this.prefix+" : "+msg;
      }
      this.prefix = null;

      const data = await apiService.getBotAnswer(msg, this.userId);
      this.currentIntent = data.metadata.INTENT;
      let responses = data.responses;
      
      // If the trigger message has been received
      if (responses.map((response) => response.text).includes("lancer_le_message_auto_echanger_conseiller")) {
        // Remove the trigger message from the responses (because we don't want the user to see it)
        responses = responses.filter(response => response.text !== "lancer_le_message_auto_echanger_conseiller");
        // Get the current time and day of the week
        const day_time = new Date().toLocaleString('fr-FR', {hour: '2-digit', minute:'2-digit', weekday:'long',  hour12: false, timeZone: 'Europe/Paris' })
        const day = day_time.split(" ")[0]
        const time = day_time.split(" ")[1]
        
        let button = "Aide et contact"
        if (time >= '08:00' && time < '16:50' && !["samedi", "dimanche"].includes(day)) {
          button = "Echanger avec un conseiller" 
        }
        responses = responses.concat(
          [
            {
              "type": "WebMessage",
              "text": "Si vous rencontrez un problème, cliquez sur le bouton \"" + button +"\".",
            },
            {
              "type": "WebMessage",
              "buttons": [
                  { "title": button }
              ],
            }
          ]
        )
      }
      
      
      this.isLoading = false;

      if (this.currentIntent == "satisfaction_review") {
          this.$emit("askSatisfaction", data.responses[0].buttons);
      }
      else if (!(hideAnswer === true)) {
        this.flux.push(
          messageFactory.createMessage("received", "user", "Chatbot Enedis")
        );

        responses.forEach((response) => {
          this.browseTypes(response);
        });
      }
    },
    setSatisfactionCount: function (value) {
      satisfaction_count = value
    },
    inactivityTime: async function () {
      function logout() {
        if (satisfaction_count < 1 && !["phrase_accueil", "contact_chat", "greetings", "bonjour", "unknown"].includes(ref.currentIntent)) {
            satisfaction_count = 1;
            ref.answer("builtinsatisfaction", true, "sentence");
            clearTimeout(time);
        }
      }
      function resetTimer() {
        clearTimeout(time);
        
        if (satisfaction_count < 1) {
          time = setTimeout(logout, 12000);
        }
      }
      if (satisfaction_count < 1) {
        var time;
        var ref = this;
        window.onload = resetTimer;
        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;
        document.onmousemove = resetTimer;
        document.onmousedown = resetTimer; // touchscreen presses
        document.ontouchstart = resetTimer;
        document.onclick = resetTimer; // touchpad clicks
        document.addEventListener("scroll", resetTimer, true);
      }
    },
  },
  watch: {
    msg: async function () {
      this.answer(this.msg, false, "sentence");
    },
    flux: function () {
      const msgZone = this.$refs.messageZone;
      msgZone.scrollTop = msgZone.scrollHeight;
      sessionStorage.setItem("flux", JSON.stringify(this.flux));
    },
  },
};
</script>

<style lang="scss" scoped>
  .chatbot-fix {
    flex: 1 1 auto;
  }

  .chatbot-body {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: auto;
    background-color: $basic-color-fond;
  }

  .chatbot-messages-zone {
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0rem 0.5rem 0.5rem 0.5rem;
    display: flex;
    flex-basis: 90%;
    max-width: 90%;
  }

  .chatbot-body a {
        color: $basic-color;
        text-decoration: underline;
  }
</style>