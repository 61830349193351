<template>
    <div id="app-chatbot">
        <ChatBot/>
    </div>
</template>

<script>
    import ChatBot from "./components/ChatBot.vue";
    export default {
        name: "app-chatbot",
        components: {
            ChatBot,
        }
    };
</script>

<style lang="scss" scoped>
    #app-chatbot {
        position: fixed;
        bottom: 5vh;
        right: 5vw;
        margin-top: 40px;
        z-index: 2000; // Notifier enedis.fr (notamment pour leur layer de recherche) si on change cette valeur
        font-family: $basic-font;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin: 0px;
        display: flex;
    }

    @media screen and (max-width: 550px) {
        #app-chatbot {
            position: fixed;
            bottom: 0vh;
            right: 0vw;
            justify-content: space-between;
            flex-direction: column;
            margin-top:10vh;
            margin-bottom:0vh;
            margin-right:0vh;
            flex-direction: column;
            display: flex;
            border-radius:0;
            z-index: 503; // Notifier enedis.fr (notamment pour leur layer de recherche) si on change cette valeur
        }
    }


</style>