<template>
      
  <div v-on:click="openChat()" v-bind:class="mode('bubble')" ></div>
</template>


<script>
  import {accessibilityService} from '../services/AccessibilityService';
  
  export default {
  name: "ChatbotBubble",
  data() {
    return {
      enabled: true
    };
  },
  methods: {
    mode: function (value) {
      return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
    },
    openChat: function() {
      this.$emit("openChatEvent", true);
    }
  }
};
</script>

<style lang="scss" scoped>
  .bubble {
    height: 65px;
    width: 65px;
    position: fixed;
    bottom: 5vh;
    right: 5vw;
    background-image: url('../assets/chatbot_bubble.svg');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover {
      background-image: url('../assets/chatbot_bubble_hover.svg');
    }
  }

    @media screen and (max-width: 550px) {
      .bubble {
        height: 65px;
        width: 65px;
        position: fixed;
        bottom: 10vh;
        right: 5vw;
        
        &:hover {
          background-image: url('../assets/chatbot_bubble_hover.svg');
        }
      }
    }

</style>