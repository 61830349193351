<template>
  <div v-bind:class="mode(`chatbot-${content.from}`)">
    <div v-bind:class="mode('chatbot-message')" aria-live="polite" role="status"> 
	  <p v-html="convertText(linkify(content.content))" ></p>
	</div>
  </div>
</template>

<script>
  import {accessibilityService} from '../../services/AccessibilityService';
  import {convertEmojis} from '../../services/emojisService';

  export default {
  name: "TextMessage",
  props: {
    content: Object
  },
  methods: {
    mode: function (value) {
      return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
    },
    convertText(text) {
        return convertEmojis(text);
    }, 
	  linkify(inputText){

	  	if(inputText.includes("file_delimiter")) {
			  var replacedText0, replacedText, titre, lien;
			  titre = inputText.split("file_delimiter")[0];
			  lien = inputText.split("file_delimiter")[1];
			  replacedText0 = `<a href="${lien}" style="color:#1423dc;text-decoration: underline; font-weight: bold;" target="_blank">${titre}</a>`;
			
			  return replacedText0
		  }
		  else {
		    var replacedText, replacePattern1, replacePattern2, replacePattern3;
      
		    //URLs starting with http://, https://, or ftp://
		    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
		    replacedText = inputText.replace(replacePattern1, '<a href="$1" style="color:#1423dc;text-decoration: underline; font-weight: bold;" target="_blank">$1</a>');

		    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
		    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" style="color:#1423dc;text-decoration: underline; font-weight: bold;" target="_blank">$2</a>');

		    //Change email addresses to mailto:: links.
		    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" style="color:#1423dc;text-decoration: underline;" >$1</a>');

        replacedText = replacedText.replaceAll("\n","<br>")
      
		    return replacedText

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chatbot-message {
  text-align: left;
  margin-top: 3px;
  margin-bottom: 7px;
  padding: 10px;
  width: fit-content;
  display: flex;
  font-size: $font-size_14PX;
  @media only screen and (max-device-width: $max_device_width_phone) {
    font-size: $font-size-min-IOS;
  }
  font-weight: 400;
  border-radius: 20px;
}

.chatbot-sent {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .chatbot-message {
    color: $msg-sent-text;
    background-color: $msg-sent-bg;
    ::selection {
      color: $msg-sent-bg;
      background: $msg-sent-text;
    }
  }
}

.chatbot-received {
  margin-right: 5%;
  .chatbot-message {
    color: $title-color;
    background-color: $msg-received-bg;

  }
}
p {
  margin : 0px 8px;
  overflow-wrap: anywhere;
}
textarea {
  font-family: $basic-font;
  color:$basic-color;
}
.chatbot-message a {
  color: $basic-color;
  text-decoration: underline; // attention: la regle css doit etre mis directement dans la balise (plus haut) sinon les lien générés ne prennent pas en compte le css du style
  font-weight: bold;
}

</style>