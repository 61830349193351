<template>
    <div  v-if="this.isDisabled==false" v-bind:class="mode('chatbot-footer')" v-bind:id="mode('chatbot-footer-id')">
        
        <div v-bind:class="mode('chatbot-input-box')">
            <span id="acessibilty-bot-answer" class="sr-only visually-hidden" aria-live="polite" role="status">
                Chatbot Enedis : Très bien, je suis ravi d'avoir pu vous aider !
            </span>
            <textarea
                label="Saisissez votre question"
                v-on:keyup.enter.exact="pushMessage(true, $event)"
                @keydown.enter.exact.prevent
                v-on:keyup="convertText(message)"
                v-model="message"
                type="text"
                v-bind:class="[mode('chatbot-input')]"
                v-bind:id="'input-id'"
                contenteditable
                @input="resize($event)"
                placeholder="Saisissez votre question"
                tabindex="1"
                :disabled="this.isDisabled"/>
                
            <div    
                aria-label="Envoyer message"
                v-on:click="pushMessage(false, $event)"
                v-on:mouseover="buttonHover"
                v-on:mouseleave="buttonLeave"
                tabindex="2"
                v-bind:class="[mode('chatbot-button'), { buttonOver: hover }]">
                Envoyer
            </div>
        </div>
    </div>
</template>
 
<script>
    import {accessibilityService} from '../services/AccessibilityService';
    import {convertEmojis} from '../services/emojisService';
    import {Picker} from 'emoji-mart-vue'

    export default {    
        name: "ChatBotFooter",
        components: {
            Picker,
        },

        data () {
            return {
                showEmoji: false,
                message: "",
                hover: false,
                emojiHover: false,
                isDisabled: false,
                suggestions: [],
                userId: sessionStorage.getItem("userId"),
                requeteArrive : true,
                scrollHeight : 0,
                nbLignes : 0
            };
        },

        mounted: function () {
            document.addEventListener("click", (evt) => {
                if (this.showEmoji) {
                    const emojiSection = document.getElementById("emoji-section");
                    let targetElement = evt.target; // clicked element
                    do {
                        if (targetElement == emojiSection) {
                            // This is a click inside. Do nothing, just return.
                            return;
                        }
                        // Go up the DOM
                        targetElement = targetElement.parentNode;
                    } while (targetElement);
                    // This is a click outside.
                    this.showEmoji = false;
                }
            });
        },
        methods: {
            mode: function (value) {
                return accessibilityService.getTheme() === 'default' ? value : value + '_accessibility';
            },
            buttonHover: function () {
                this.hover = true;
            },
            buttonEmojiHover: function () {
                this.emojiHover = true;
            },
            buttonLeave: function () {
                this.hover = false;
            },
            buttonEmojiLeave: function () {
                this.emojiHover = false;
            },
            pushMessage: function (textarea, event) {
                if (this.message == "") {
                    return;
                }
                this.$emit("inputData", this.message);
                this.message = "";

                this.resize(event);
            },
            convertText: async function (text) {
                this.message = convertEmojis(text);
            },
            getEmojiIcon () {
                const path = this.emojiHover ? 'emoji-hover.svg' : 'emoji.svg';
                return require('../assets/' + path);
            },
            addEmoji (emoji) {
                this.message = this.message.concat(emoji.native);
            }, 
            resize(e) {

                if (e.target.scrollHeight > this.scrollHeight) {
                    this.nbLignes +=1
                    e.target.scrollTo(0, e.target.scrollHeight);
                }
                if (e.target.scrollHeight < this.scrollHeight) {
                    this.nbLignes -=1
                }
                if (this.message == "") {
                    this.nbLignes = 0
                }

                if (this.nbLignes <= 1) {
                    document.querySelector("#input-id").classList.remove("multiplelines");
                }
                else {
                    document.querySelector("#input-id").classList.add("multiplelines");
                }

                this.scrollHeight = e.target.scrollHeight
            },
            setDisable(disabled) {
                if (disabled === true) { this.message = "" }
                this.isDisabled = disabled
            }
        }
    };
</script>


<style lang="scss" scoped>
    .chatbot-footer {
        display: flex;
        align-items: center;
        border-top-width: 2px;
        border-top-color: $borders;
        min-height: 5rem;
        width: 100%;
        background-color: $basic-color-fond;
        border-bottom-left-radius: 1.3rem;
        border-bottom-right-radius: 1.3rem;
        
        @media screen and (max-width: 550px) {
            border-bottom-left-radius: 0rem;
            border-bottom-right-radius: 0rem;
        }
    }

    .chatbot-footer.footerboxshadow {
        box-shadow: 0 -5px 20px -20px #000;
    }

    .chatbot-input-box {
        flex-basis: 100%;
        align-items: center;
        display: flex;
        justify-content: space-evenly;

        @media screen and (max-width: 550px) {
            margin-bottom: 2vh;
            border-radius: 20px;
        }
    }

    .chatbot-input {
        border: solid 1px lightgray;
        background-color: white;
        border-radius: 2rem;
        border-style: solid;
        width: 76%;
        max-height: 60px;
        box-sizing: content-box;
        height:30px;
        line-height:18px;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: $font-size_14PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
        font-family: $basic-font;
        border-color: $borders;
        outline: none;
        color: $input-color;
        resize: none;
        padding-left: 22px;
        padding-bottom: 2px;
        padding-top: 12px;
        padding-right: 20px;
        margin-left: 26px;
    }

    .chatbot-input.multiplelines {
        padding-bottom: 0px;
        height:60px;
    }

    .chatbot-input:disabled {
        background-color: #e5e5e5;
    }
    .chatbot-input:disabled::placeholder {
        color: transparent;
    }
    .chatbot-input::placeholder {
            padding-top: 2px;
            color: transparent;
    }

    .chatbot-button_emoji {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-right: -1rem;
        border-radius: 10px;
        width: 2.5rem;
        height: 2rem;
    }

    .chatbot-button {
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #1423DC;
        height: 45px;
        width: 90px;
        padding: 0px 22px;
        border-radius: 23px;
        color: white;
        margin: 0px 16px 0px 10px;
    }

    .buttonOver {
        background-color: #232873;
        color: white;
        cursor:pointer;
    }

    /*    CUSTOM SCROLLBAR    */
    /*------------------------*/
    /* width */
    ::-webkit-scrollbar {
        width: 9px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 10px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #666; 
    }
    .visually-hidden {
	    position: absolute !important;
	    width: 1px !important;
	    height: 1px !important;
	    padding: 0 !important;
	    margin: -1px !important;
	    overflow: hidden !important;
	    clip: rect(0,0,0,0) !important;
	    white-space: nowrap !important;
	    border: 0 !important;
    }

</style>
