const axios = require('axios');

// const server = require(`../environnement/${process.env.VUE_APP_ENV}`).server;

module.exports = {
    getBotAnswer: async function (message, userId) {
        const myApi = axios.create({
            baseURL: process.env.VUE_APP_URL_ROUTEUR	
        });

        return myApi.post(
            process.env.VUE_APP_ROUTEUR_ENDPOINT, 
            {
                query: message,
                userId: userId
            }, 
            {
                headers: { 'Content-Type': 'application/json' }
            }
		).then((result) => {
            return result.data;
        }).catch((error) => {
            return [{text: process.env.VUE_APP_MESSAGE_INDISPO}]
        });
    },
    loadFile: async function (url) {

        const myApi = axios.create({
			baseURL: process.env.VUE_APP_URL_ROUTEUR,
            responseType: 'arraybuffer',
			headers: {
	            'Access-Control-Allow-Origin': '*'
			}
        });

        return myApi.get(url).then(response => {
            var arrayBufferView = new Uint8Array(response.data);
            var blob = new Blob([arrayBufferView], {type: response.headers['content-type'].toLowerCase()});
            return blob;
        }).catch(() => console.log('error occured'))
    },
    getSuggestionList: async function (message, userId) {
        const myApi = axios.create({
            baseURL: process.env.VUE_APP_PROXY_URL	
        });

        return myApi.post(
            "/suggestions", 
            {
                search: message,
                userId: userId
            }, 
            {
                headers: { 'Content-Type': 'application/json' }
            }
		).then((result) => {
            return result.data;
        }).catch((error) => {
            return null
        });
    },
    sendWebsiteLocationPing: async function(userId) {
        const myApi = axios.create({
            baseURL: process.env.VUE_APP_PROXY_URL	
        });

        return myApi.post(
            "/user-action-log", 
            {
                login: userId,
                newData: window.location.href,
                namespace: "app",
                applicationId: "adlinky",
                actionType: "URL ACTUEL"
               }, 
            {
                headers: { 'Content-Type': 'application/json' }
            }
		).then(() => {
            // return result.data;
        }).catch((error) => {
            console.error(error)
        });
    }
}
