    exports.default = {}

exports.createMessage = ((from, type, content) => {
    if (type === "text") {
        return {
            type: type,
            data: {
                from: from,
                content: content
            }
        }
    } else if (type === "buttons") {
        let value = [];
        content.forEach((item) => {
            const regex = /\+/g;
            value.push({
                title: item.title,
                payload: item.payload,
                url: item.url
            });
        });

        if (value.length > 0) {
            return {
                type: type,
                data: {
                    from: from,
                    themes: value
                }
            }
        } else {
            return;
        }

    } else if (type === "file") {

		var name, url_bot, delimiter;
		delimiter = "file_delimiter";
        name = content.name.concat(delimiter);
        url_bot = content.url;
        url_bot = process.env.VUE_APP_URL_ROUTEUR + '/file' + url_bot.split('/f')[1];
        var lastThree = url_bot.substr(url_bot.length - 3);
        if ((lastThree === "png") || (lastThree === "jpg") || (lastThree === "peg") ){
            return {
                type: "image",
                data: {
                    from: from,
                    name: content.name,
                    url: url_bot,
                }
            }
        }
        else {
            return {
                type: "text",
                data: {
                    from: from,
                    content: name.concat(url_bot)
                }
            }
        }   
    }
    else if (type === "user") {
        return {
            type: type,
            data: {
                from: from,
                userName: content,
            }
        }
    }

});
